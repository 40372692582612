.section-5 {
  padding-top: 105px;
  padding-bottom: 116px;
  overflow: hidden;

  @include _460 {
    padding-top: 70px;
  }

  h2 {
    font-size: 5.5rem;
    @extend %med;
    margin-bottom: 36px;

    @include _992 {
      font-size: 4.4rem;
    }

    @include _768 {
      font-size: 3.4rem;

      br {
        display: none;
      }
    }

    @include _460 {
      font-size: 3rem;
    }
  }

  @extend %cards;

  .card {
    margin-bottom: 30px;

    @include _576 {
      width: 50%;
    }

    @include _460 {
      width: 100%;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        width: 94%;
        height: 2px;
        background-color: $blue-main;
        bottom: -10px;
      }

      &-head {
        margin-bottom: 10px !important;
      }

      h3 br,
      p br {
        display: none;
      }

      p:before {
        display: none;
      }
    }

    @include _410 {
      width: 100%;
      margin: 0 auto 30px auto;
    }

    @include _375 {
      padding: 0 15px;
    }
  }



  .bg-pic2 {
    right: -59px;
    top: 66px;



    @include _1650 {
      right: -167px;
      top: 71px;

      img {

        @include _1400 {
          display: none;
        }
      }
    }

    @include _1400 {
      right: -192px;
      top: 459px;
    }

    @include _1200 {
      display: none;
    }
  }

}