.section-3 {

  padding-bottom: 105px;
  margin-top: -290px;


  h2 {
    font-size: 4.4rem;
    @extend %med;
    text-align: center;
    padding-top: 105px;
    margin-bottom: 40px;

    @include _768 {
      font-size: 3.4rem;
    }

    @include _460 {
      font-size: 3rem;
    }

  }

  .slick {
    &-slide {
      margin: 0 27px;

      @include _1400 {
        margin: 0 -140px;

        @include _992 {
          margin: 0 -270px;
        }

        img {
          width: 70%;
          margin: 0 auto;

          @include _992 {
            width: 50%;
          }

          @include _768 {
            width: 45%;
          }

          @include _576 {
            width: 40%;
          }
        }
      }
    }

    &-prev {
      transform: rotate(180deg);
      left: 25px;

      @include _1400 {
        left: 110px;
      }

      @include _1200 {
        left: 25px;
      }

      @include _768 {
        left: -15px;
      }

      @include _576 {
        left: 0px;
      }

      &:hover {
        transform: translateY(-3px) rotate(180deg);
      }
    }

    &-next {
      right: 25px;

      @include _1400 {
        right: 110px;
      }

      @include _1200 {
        right: 25px;
      }

      @include _768 {
        right: -15px;
      }

      @include _576 {
        right: 0px;
      }

      &:hover {
        transform: translateY(-3px);
      }
    }


    &-arrow {
      text-decoration: none;
      display: inline-block;
      transition: .3s;


      position: absolute;
      font-size: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      background-color: $white;
      top: 200%;
      border-radius: 50%;
      z-index: 1;

      @include _1400 {
        top: 170%;
      }

      @include _992 {
        width: 70px;
        height: 70px;
        top: 150%;
      }

      @include _768 {
        width: 50px;
        height: 50px;
        top: 145%;
      }

      @include _576 {
        top: 130%;
      }

      @include _375 {
        top: 125%;
      }

      &:after {
        content: "";
        width: 65px;
        height: 65px;
        background-color: $blue-main;
        border-radius: 50%;
        background-image: url('../img/arrow.png');
        background-repeat: no-repeat;
        background-position: center;

        @include _992 {
          width: 40px;
          height: 40px;
        }

        @include _768 {
          width: 30px;
          height: 30px;
          background-size: 10px;
        }
      }

    }

  }
}