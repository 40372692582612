.section-10 {
  background-image: url('../img/bg4.jpg');
  background-size: cover;
  background-position: center;
  color: $white;
  height: 450px;

  @include _992 {
    padding-top: 105px;
  }

  h2 {
    font-size: 3.8rem;
    @extend %med;

    @include _768 {
      font-size: 3.4rem;

      br {
        display: none;
      }
    }

    @include _460 {
      font-size: 2.8rem;
    }
  }

  p {
    font-size: 2.4rem;
    margin-top: 21px;
  }
}