.section-1 {
  padding-top: 214px;
  background-image: url('../img/header-bg.jpg');
  padding-bottom: 100px;
  background-size: cover;
  background-position: center;

  @include _460 {
    padding-bottom: 70px;
  }

  h1 {
    font-size: 6rem;
    margin-bottom: 24px;
    @extend %bold;

    @include _768 {
      font-size: 4rem;
    }
  }

  p {
    font-size: 2.4rem;

    @include _992 {
      br {
        display: none;
      }
    }

    @include _375 {
      font-size: 1.8rem;
    }
  }

  .thin_text {
    @extend %light;
    font-size: 3.2rem;
    margin-bottom: 28px;

    @include _992 {
      br {
        display: none;
      }
    }

    @include _768 {
      font-size: 2.8rem;
    }

    @include _375 {
      font-size: 2.4rem;
    }

  }

  .btn {
    margin-top: 71px;
    color: $white;
    @extend %med;
    font-size: 1.8rem;
    padding: 0 40px;
    width: 410px;
    height: 93px;
    border-radius: 8px;

    @include _460 {
      width: 100%;
      padding: 0;
    }

    @include _375 {
      padding: 0 5px;

      br {
        display: none;
      }
    }
  }

  @include floating-button(linear-gradient($blue-main 0%, $blue-second 100%), $blue-main)
}