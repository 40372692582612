header {
  position: absolute;
  padding-top: 32px;
  @extend %w-100;
  top: 0;

  @include _576 {
    padding-top: 12px;
  }

  .logo {
    font-size: 1.5rem;

    p {
      margin-top: 5px;
    }

    @include _992 {
      font-size: 1.3rem;
    }


    img {
      margin-right: 16px;

      @include _992 {
        width: 110px;
      }

      @include _375 {
        margin-right: 0;
        width: 100px;
      }
    }

    @include _768 {
      flex-direction: column;
    }

    @include _576 {
      align-items: flex-start !important;
    }

    @include _460 {
      margin-top: -13px;
    }

    @include _410 {
      margin-top: -10px;

      p {
        font-size: 1rem;
      }
    }
  }

  .contact-us {
    align-items: center;

    @include _768 {
      flex-direction: column;
    }

    .phone {
      font-size: 2.6rem;
      color: $black;
      @extend %med;
      margin-right: 35px;

      @include _992 {
        margin-right: 20px;
        font-size: 2rem;
      }

      @include _768 {
        margin-right: 0;
        margin-bottom: 15px;
      }

      @include _375 {
        font-size: 1.8rem;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    @include floating-button (transparent, rgba(0, 0, 0, 0.2));



    .call-me {
      font-size: 1.6rem;
      color: rgb($black, 0.5);
      border-radius: 8px;
      border: 2px solid rgba(0, 0, 0, 0.2);
      box-shadow: none;
      width: 234px;
      height: 61px;

      @include _992 {
        width: 170px;
      }

      @include _410 {
        height: 41px;
      }

      @include _375 {
        width: 150px;
        font-size: 1.4rem;
      }

      &:hover {
        box-shadow: 0px 5px 10px rgb(0 0 0 / 14%);
        color: $white;
        background: linear-gradient($blue-main 0%, $blue-second 100%) !important;
        border: none;
      }
    }
  }

}