footer {
  @include _410 {
    .container {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
    }


  }

  background-image: url('../img/bg-footer.png');
  background-size: cover;
  background-position: center;
  padding-top: 20px;
  padding-bottom: 40px;

  .pol {
    margin-top: -20px;



    @include _375 {
      margin-top: -29px;
      width: 35%;
    }


    a {
      font-size: 1.5rem;
      color: $black;



      &:hover {
        cursor: pointer;
        text-decoration: underline !important;
      }

      @include _460 {
        font-size: 1.2rem;
      }

      @include _410 {
        font-size: 1rem;

      }
    }

  }

  .logo {
    font-size: 1.5rem;

    p {
      margin-top: 5px;
    }

    @include _992 {
      font-size: 1.2rem;

      img {
        width: 100px;
      }
    }

    @include _576 {
      flex-direction: column;
      align-items: flex-start !important;
    }

    img {
      margin-right: 16px;
    }

    @include _410 {
      margin-right: 19px;
      flex-direction: row;
      align-items: start !important;
      font-size: 1rem;
    }
  }

  .contact {
    @include _576 {
      flex-direction: column;
      width: 50%;
    }

    &-info {
      font-size: 1.5rem;

      @include _992 {
        font-size: 1.2rem;
      }

      p {
        margin-bottom: 10px;

        @include _410 {
          br {
            display: none;
          }
        }
      }
    }

    &-call {
      text-align: right;
      align-items: flex-end;
      margin-left: 88px;

      @include _992 {
        margin-left: 25px;
      }

      @include _768 {
        margin-left: 0;
      }

      @include _576 {
        align-items: flex-start !important;
      }

      .phone {
        font-size: 2.6rem;
        @extend %med;
        color: $black;

        @include _992 {
          font-size: 1.8rem;
        }

        @include _375 {
          font-size: 1.6rem;
        }
      }

      .call-me {
        font-size: 1.5rem;
        color: $blue-main;
        padding-left: 0;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .mail {
        margin-top: 10px;
        font-size: 1.5rem;
        color: $black;

        img {
          margin-right: 6px;
          max-width: 20px;
        }

        @include _460 {
          font-size: 1.2rem;
        }

      }
    }
  }

  .footer-soc {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    margin-top: 20px;

    @include _410 {
      justify-content: flex-end;
    }

    li {
      list-style: none;
      margin-right: 25px;

      @include _410 {
        margin-right: 15px;
      }

      &:last-child {
        margin-right: 0;
      }


    }

  }
}