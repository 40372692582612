.mystickyelements-fixed {
  position: fixed;
  z-index: 99999;
  font-family: 'MontserratMed';
  top: 65%;
  display: flex;
  align-items: center;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 1s ease 0s;
  -moz-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
}

.mystickyelements-position-right.mystickyelements-entry-effect-slide-in.entry-effect {
  right: 0;
  transition: all 0s ease 0s;
}

.mystickyelements-fixed ul {
  list-style: none;
  display: inline-block;
  vertical-align: top;
}

.mystickyelements-fixed ol,
.mystickyelements-fixed ul {
  margin: 0;
  padding: 0;
}

.mystickyelements-fixed ul li {
  position: relative;
  width: 53px;
  display: none;
  margin: 0;
  padding: 0;
}

.mystickyelements-fixed ul li {
  cursor: pointer;
  color: #ffffff;
}

.mystickyelements-fixed ul li.element-desktop-on {
  display: block;
}

span.mystickyelements-social-icon a,
span.mystickyelements-social-icon {
  width: 53px;
  height: 53px;
  color: #fff;
  float: left;
  position: relative;
  text-align: center;
  font-size: 17px;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  cursor: pointer;
  /* box-shadow: 0 3px 6px rgb(0 0 0 / 16%); */
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.2;
  flex-wrap: wrap;
  text-decoration: none;
}

.mystickyelements-position-right.mystickyelements-templates-roundad li:not(.mystickyelements-contact-form) span.mystickyelements-social-icon a,
.mystickyelements-position-right.mystickyelements-templates-roundad li:not(.mystickyelements-contact-form) span.mystickyelements-social-icon {
  border-radius: 25px 0 0 25px !important;
}

.mystickyelements-fixed li:after {
  content: "";
  display: block;
  clear: both;
}

span.mystickyelements-social-text.mystickyelements-social-no-link,
span.mystickyelements-social-text a {
  color: #fff;
  text-decoration: none;
  display: inline-block;
  vertical-align: top;
  min-width: 137px;
  height: 100%;
  padding-top: 19px;
  padding-right: 10px;
  padding-left: 13px;
  font-family: 'Open Sans', sans-serif;
}

span.mystickyelements-social-text {
  color: #ffffff;
  border-radius: 0 10px 10px 0;
  font-size: 14px;
  float: left;
  line-height: 1.2;
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  white-space: nowrap;
  -webkit-transform: rotateY(90deg);
  -moz-transform: rotateY(90deg);
  transform: rotateY(90deg);
  -webkit-transform-origin: 0 0 0;
  -moz-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.mystickyelements-position-right .element-contact-form,
.mystickyelements-position-right span.mystickyelements-social-text {
  -webkit-transform-origin: 100% 0 0;
  -moz-transform-origin: 100% 0 0;
  transform-origin: 100% 0 0;
  left: auto;
  right: 100%;
}

.mystickyelements-position-right.mystickyelements-templates-roundad span.mystickyelements-social-text {
  border-radius: 25px 0 0 25px;
}

.mystickyelements-on-click.mystickyelements-fixed ul li.elements-active span.mystickyelements-social-text,
.mystickyelements-on-hover.mystickyelements-fixed ul li:hover span.mystickyelements-social-text {
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  transform: rotateY(0);
}

.mystickyelements-on-click.mystickyelements-fixed ul li:not(.mystickyelements-contact-form).elements-active .mystickyelements-social-icon,
.mystickyelements-on-hover.mystickyelements-fixed ul li:not(.mystickyelements-contact-form):hover .mystickyelements-social-icon {
  border-radius: 0 !important;
}