.section-6 {
  background-image: url('../img/bg3.jpg');
  background-size: cover;
  background-position: center;
  color: $white;
  margin-top: -50px;
  height: 450px;

  @include _992 {
    background-position: 80% 50%;
  }

  h2 {
    font-size: 3.8rem;
    @extend %med;

    @include _576 {
      br {
        display: none;
      }
    }

    @include _410 {
      font-size: 3rem;
    }
  }

  p {
    margin-top: 28px;
    font-size: 2.4rem;
  }
}