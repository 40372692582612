.section-2 {
  overflow: hidden;
  padding-bottom: 300px;



  @include _460 {
    padding-bottom: 250px;
  }

  h2 {
    font-size: 4.4rem;
    line-height: 57px;
    @extend %med;
    padding-top: 105px;
    margin-bottom: 30px;

    @include _768 {
      font-size: 3.4rem;
    }

    @include _460 {
      font-size: 3rem;
      line-height: 44px;
      padding-top: 70px;
    }
  }

  .bg-pic1 {
    left: 0;
    top: 231px;

    @include _1650 {
      left: -104px;
      top: 281px;
    }

    @include _1400 {
      display: none;
    }
  }

  .bg-pic2 {
    right: 0;
    bottom: 0px;

    @include _1650 {
      right: -110px;
    }

    @include _1200 {
      display: none;
    }
  }

  @extend %cards;

  @include _992 {
    .card {
      margin-bottom: 30px;

      @include _576 {
        width: 50%;
      }

      @include _460 {
        width: 100%;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          width: 94%;
          height: 2px;
          background-color: $blue-main;
          bottom: -10px;
        }

        &-head {
          margin-bottom: 10px !important;
        }

        h3 br,
        p br {
          display: none;
        }

        p {
          &:before {
            display: none;
          }
        }
      }

      @include _410 {
        width: 100%;
        margin: 0 auto 30px auto;
        padding: 0 15px;

        &:after {
          width: 92%;
        }
      }

      @include _375 {
        padding: 0 20px;

        &:after {
          width: 88%;
        }
      }
    }
  }


  .calc {
    img {
      @extend %w-100;
    }

    p {
      color: $blue-second;
      font-size: 1.8rem;
      text-align: center;
      margin-top: 27px;
    }
  }


}