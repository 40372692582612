.section-tnx {
  height: 100vh;

  h1 {
    padding-top: 20px;

    @include _410 {
      font-size: 2.8rem;
    }
  }
}