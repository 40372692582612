.section-4 {
  background-image: url('../img/bg1.jpg');
  background-size: cover;
  background-position: center;
  color: $white;
  margin-bottom: 30px;
  height: 450px;

  h2 {
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 37px;

    strong {
      @extend %med;
      font-size: 3.6rem;
    }

    @include _768 {
      br {
        display: none;
      }
    }
  }


}

.section-4-2 {
  background-image: url('../img/bg2.jpg');
  background-size: cover;
  background-position: center;
  color: $white;
  height: 450px;
  position: relative;

  h2 {
    @extend %med;
    font-size: 3.8rem;

    @include _768 {
      font-size: 3.6rem;

      br {
        display: none;
      }
    }

    @include _460 {
      font-size: 2.7rem;
    }
  }

  p {
    margin-top: 35px;
    font-size: 2.4rem;

    @include _768 {
      br {
        display: none;
      }
    }

    @include _460 {
      font-size: 2rem;
    }
  }

  .bg-pic1 {
    left: -80px;
    bottom: -200px;
    z-index: 99;

    @include _1650 {
      bottom: -160px;
      left: -130px;

      img {
        width: 75%;


      }
    }

    @include _1200 {
      display: none;
    }
  }
}