@mixin _1650 {
  @media (max-width: 1650px) {
    @content;
  }
}

@mixin _1400 {
  @media (max-width: 1400px) {
    @content;
  }
}

@mixin _1200 {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin _992 {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin _768 {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin _576 {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin _460 {
  @media (max-width: 460px) {
    @content;
  }
}

@mixin _410 {
  @media (max-width: 410px) {
    @content;
  }
}

@mixin _375 {
  @media (max-width: 375px) {
    @content;
  }
}