.section-9 {
  padding: 105px 0;



  .bg-pic1 {
    right: 14px;
    bottom: -50px;

    @include _1400 {
      img {
        width: 80%;
      }
    }

    @include _1200 {
      display: none;
    }
  }

  h2 {
    font-size: 3.8rem;
    @extend %med;
    margin-bottom: 40px;
  }

  .howwork {
    @include _1200 {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @include _460 {
      width: 100%;
      padding: 0 15px;
      --bs-gutter-x: 0rem;
    }

    &-item {
      position: relative;
      width: 371px;
      height: 162px;
      border: 1px solid rgba(156, 156, 156, 0.28);
      border-radius: 8px;
      padding: 20px;
      margin-bottom: 30px;
      margin-right: 30px;



      @include _1400 {
        width: 369px;
        margin-bottom: 15px;
        margin-right: 15px;
      }

      @include _460 {
        width: 100%;
        margin-right: 0;
      }

      @include _410 {
        p {
          br {
            display: none;
          }
        }
      }

      &:nth-child(3n+3) {
        margin-right: 0;

        @include _1200 {
          margin-right: 15px;
        }

        @include _460 {
          margin-right: 0;
        }
      }

      p,
      .lower {
        font-size: 1.8rem;
        line-height: 23px;

        @include _410 {
          width: 85%;
        }

        @include _375 {
          font-size: 1.5rem;
        }

        strong {
          font-size: 2.2rem;
          @extend %med;

          @include _410 {
            display: block;
          }

          @include _375 {
            font-size: 1.7rem;
          }
        }
      }

      &:after {
        color: rgba(72, 150, 255, 0.2);
        font-size: 10.5rem;
        @extend %med;
        position: absolute;
        right: 5px;
        top: 35px;
      }

      &:nth-child(1):after {
        content: "1";
      }

      &:nth-child(2):after {
        content: "2";
      }

      &:nth-child(3):after {
        content: "3";
      }

      &:nth-child(4):after {
        content: "4";
      }

      &:nth-child(5):after {
        content: "5";
      }

      &:nth-child(6):after {
        content: "6";
      }

    }
  }
}