#callback {
  .modal-dialog {
    border: none;
  }

  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 15px;
    height: 15px;
    background-size: cover;
    cursor: pointer;
    z-index: 9;
  }

  @include form(100%, none, 85px);

  form {


    .bordered {
      border-radius: 8px;
      padding: 30px 29px;
      position: relative;

      @include _410 {
        padding: 20px 19px;
      }

      .h3 {
        font-size: 3rem;
        line-height: 39px;
        margin-bottom: 30px;
      }

      .label {
        font-size: 1.8rem;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .form-control {
        background-color: #e5e5e5;
        border: none;
        border-radius: 8px;
        font-size: 1.8rem;
        @extend %med;
        padding-left: 38px;
      }

      .main-btn {
        color: $white;
        @extend %med;
        font-size: 1.8rem;
        padding: 0 46px;
        height: 85px;
        border-radius: 8px;

        @include _460 {
          padding: 0;
        }
      }

      @include floating-button(linear-gradient($blue-main 0%, $blue-second 100%), $blue-main);

      .form-check {
        label {
          padding-left: 10px;

          a {
            color: $blue-second;
          }

          &:before {
            border: none;
            background-color: $blue-second;
            width: 25px;
            height: 25px;
            top: -5px;
          }

          &:after {
            width: 15px;
            height: 15px;
            left: 5px;
            top: 0px;
          }
        }
      }
    }
  }

  .form-check label {
    padding-left: 10px;
  }

  .form-check .jq-checkbox.checked .jq-checkbox__div {
    width: 14px;
    height: 8px;
    margin: 0px 0 5px 0px;
    border-bottom: 2px solid $white;
    border-left: 2px solid $white;
    transform: rotate(-50deg);
  }

  .form-check .jq-checkbox.focused,
  .form-check .jq-radio.focused {
    border: none;
  }

  .form-check .jq-checkbox,
  .form-check .jq-radio {
    vertical-align: 0px;
    width: 26px;
    height: 25px;
    margin: 0 0px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: $blue_second;
    box-shadow: none;
    cursor: pointer;
    margin-left: 30px;
  }

  label {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #bbb;
    letter-spacing: .28px;
    position: relative;
    cursor: pointer;

    &:before {
      display: none;
    }

    &:after {
      display: none;
    }

    a {
      cursor: pointer;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }

}