html {
  font-size: 10px;
}

body {
  font-family: 'Roboto', sans-serif;
}

* {
  outline: none;

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  button {
    border: none;
    background: transparent;
  }

  .br {
    display: none !important;
  }

  @include _410 {

    .br {
      display: block !important;
    }
  }


}

.err {
  display: none !important;
}