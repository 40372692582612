$blue-main: #337BDA;
$blue-second: #4896FF;
$black: #000;
$white: #fff;
$blue-trans:rgb(#4896FF, 20%);

%med {
  font-weight: 500;
}

%light {
  font-weight: 300;
}

%bold {
  font-weight: 700;
}

%w-100 {
  width: 100%;
}

%dn {
  display: none;
}

%cards {
  .card-block {
    padding-top: 47px;

    .card-head {
      height: 86px;
      margin-bottom: 34px;
    }

    h3 {
      @extend %med;
      font-size: 1.8rem;
      line-height: 23px;
      margin-left: 15px;
    }

    p {
      font-size: 1.6rem;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 56px;
        height: 2px;
        background-color: $blue-main;
        top: -18px;
      }
    }
  }
}