.section-7 {
  padding: 105px 0;

  @include _460 {
    padding: 70px 0;
  }

  .bg-pic1 {
    left: 0;
    top: 190px;

    img {
      width: 80%;
    }

    @include _1650 {
      left: 0;
      top: 395px;
      z-index: -1;

      img {
        width: 32%;

        @include _1400 {
          width: 26%;
        }
      }
    }

    @include _1200 {
      display: none;
    }
  }

  h2 {
    font-size: 3.8rem;
    @extend %med;
    margin-bottom: 40px;

    @include _768 {
      font-size: 3.4rem;
    }

    @include _410 {
      font-size: 3rem;
    }
  }

  p {
    font-size: 3.8rem;
    line-height: 49px;
    margin-bottom: 40px;

    @include _992 {
      br {
        display: none;
      }
    }

    @include _768 {
      font-size: 2.8rem;
      line-height: 39px;
    }

    @include _410 {
      font-size: 2.2rem;
      line-height: normal;
    }
  }

  .slick {


    &-slider {
      text-align: center;
      padding-bottom: 105px;
    }

    &-slide {
      img {
        margin: 0 auto;

        @include _1400 {
          width: 90%;
        }
      }
    }

    &-prev {
      transform: rotate(180deg);
      left: -25px;

      @include _576 {
        left: 0px;
      }

      &:hover {
        transform: translateY(-3px) rotate(180deg);
      }

    }

    &-next {
      right: -25px;

      @include _576 {
        right: 0px;
      }

      &:hover {
        transform: translateY(-3px);
      }
    }


    &-arrow {
      transition: .3s;
      position: absolute;
      font-size: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      background-color: $white;
      top: 85px;
      border-radius: 50%;
      z-index: 1;

      @include _1200 {
        top: 50px;
      }

      @include _992 {
        width: 70px;
        height: 70px;
      }

      @include _768 {
        width: 50px;
        height: 50px;
        top: 40px;
      }

      @include _576 {
        top: 140px;
      }

      @include _410 {
        top: 110px;
      }

      @include _375 {
        top: 85px;
      }

      &:after {
        content: "";
        width: 65px;
        height: 65px;
        background-color: $blue-main;
        border-radius: 50%;
        background-image: url('../img/arrow.png');
        background-repeat: no-repeat;
        background-position: center;

        @include _992 {
          width: 40px;
          height: 40px;
        }

        @include _768 {
          width: 30px;
          height: 30px;
          background-size: 10px;
        }
      }
    }
  }

  .objects {


    &-item {
      margin-bottom: 30px;
      padding-right: calc(var(--bs-gutter-x) * 1.5);
      position: relative;

      img {
        @extend %w-100;
      }

      &:after {
        position: absolute;
        font-size: 2.4rem;
        bottom: 20px;
        left: 30px;

        @include _992 {
          bottom: 2px;
        }

        @include _768 {
          bottom: 10px;
        }



        @include _410 {
          bottom: 2px;
        }

        @include _375 {
          left: 15px;
        }

      }

      &:nth-child(1):after {
        content: "Дом правительства РФ";
      }

      &:nth-child(2):after {
        content: "Кремль";
      }

      &:nth-child(3):after {
        content: "МГУ";
      }

      &:nth-child(4):after {
        content: "Москва Сити";
      }

      @include _460 {
        padding-right: calc(var(--bs-gutter-x) * 0.5);
      }
    }
  }
}