@mixin floating-button($color, $color-shadow) {
  .floating-button {
    text-decoration: none;
    display: inline-block;
    background: $color;
    box-shadow: 0 8px 15px rgba(0, 0, 0, .1);
    transition: .3s;
  }

  .floating-button:hover {
    background: $color;
    box-shadow: 0 10px 10px rgba($color-shadow, .4);
    transform: translateY(-3px);
  }
}