@mixin form($width_bordered, $color_bordered, $form_height) {
  form {
    .bordered {
      border: $color_bordered;
      width: $width_bordered;
      height: 100%;

      .form-block-input {
        margin-bottom: 20px;
      }

      .label {
        display: block;
      }

      .form-control {
        width: 100%;
        height: $form_height;
      }

      .err {
        display: none;
      }

      .main-btn {
        width: 100%;
        height: $form_height;
      }

      .disabled {
        background: #e5e5e5 !important;
      }
    }
  }
}