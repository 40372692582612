#calculator_block {
  background: transparent;
  color: #4e4343;
  border-radius: 8px;
  border: 1px solid $blue_main;
}

.div_table {
  display: table;
  width: 100%;
}

.div_table .div_td {
  display: table-cell;
}

.div_table .div_tr {
  display: flex;
}

.tribler .div_tr {
  margin-bottom: 60px;
}

.div_table .strong {
  justify-content: space-between;

}



#calculator_block .calculator_inner .div_td.left .div_table .div_td {
  padding: 15px 10px 15px 0;
  vertical-align: middle;
}

#calculator_block .div_table.tribler .div_tr .div_td:nth-child(odd) {
  width: 100px;
}

#calculator_block .calculator_inner .div_td.left {
  width: 60%;
  padding: 0;
  vertical-align: top;
}

#calculator_block .title_h2 {
  color: #000;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
  text-align: left;
}

.s-message {
  display: none;
}

.left_blik .title_h2 {
  font-size: 48px;
  font-weight: normal;
  position: relative;
  line-height: normal;
  text-align: center;
  text-align: left;
}

.title_h3 {
  width: 490px;
  height: 28px;
  color: #000000;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  margin-bottom: 80px;
}

#calculator_block .jq-number__field {
  border: 1px solid $blue_main;
  border-radius: 8px;
  font-family: "Roboto";
  color: #000;
  box-sizing: border-box;
  background: #fff;
  font-size: 24px;
  width: 96px;
  height: 67px;
}



#calculator_block .calculator_inner .div_td.left .left_blik {
  padding: 30px 35px;
}

#calculator_block .calculator_inner .div_td.right {
  border-left: 2px dotted #FFFFFF;
  vertical-align: top;
}

#calculator_block .calculator_inner .div_td.right .right_inner {
  padding: 40px 20px;
  font-size: 1.1em;
}

#calculator_block .div_table.digits .div_tr .div_td {
  padding: 10px 0;
  font-size: 22px;
}

.align_right {
  text-align: right;
}

.small {
  font-size: 0.85em;
}

#calculator_block .user_form {
  border-top: 1px solid $blue_main;
  padding-top: 40px;
  margin-top: 15px;
}



.cols {
  margin: 1px -1%;
  display: flex;
  flex-direction: column;
}

.cols .col {
  margin: 5px 1%;
  float: left;
  width: 98%;
}

.cols .col.col_2 {
  width: 98%;
}

.form_success.in_green,
.form_success.in_green a {
  color: #FFFFFF;
}

.form_success.in_green,
.form_success.in_green a {
  color: #FFFFFF;
}

.div_td>label {
  font-size: 18px !important;
}

#calculator_block {
  .jq-number__field input {
    width: 100%;
    padding: 16px 13px;
  }

  .jq-number__spin.minus:after {
    border-top: 7px solid $blue_main;

  }

  .jq-number__spin.plus:after {
    border-bottom: 7px solid $blue_main;
  }

  .jq-checkbox.rounded {
    border: 1px solid #000;
  }

  .jq-checkbox.rounded .jq-checkbox__div {
    top: 2px;
    background: $blue_main;
  }

  input[type=text].styler,
  input[type=tel].styler {
    background-color: #e5e5e5;
    border: none;
    border-radius: 8px;
    padding-left: 38px;
    color: #000;
    font-size: 18px;
    font-family: inherit;
    text-align: left;
    height: 85px;
  }

  .col {
    margin-bottom: 10px;
  }

  .cols:nth-child(1) {
    margin-bottom: 12px;
  }

  .form_success {
    margin-top: 0;
  }

  input[type=submit].styler.w100 {
    text-decoration: none;
    display: inline-block;
    background: linear-gradient(#337BDA 0%, #4896FF 100%);
    box-shadow: 0 8px 15px rgb(0 0 0 / 10%);
    transition: .3s;
    border-radius: 8px;
    height: 85px;
    color: #fff;
    font-size: 1.8rem;
    font-family: inherit;
    text-transform: unset;
  }

  .form-check label:before {
    border: none;
    background-color: #4896ff;
    width: 25px;
    height: 25px;
    top: -5px;
  }

  .form-check label:after {
    width: 15px;
    height: 15px;
    left: 5px;
    top: 0px;
  }

  .form-check label {
    padding-left: 10px;
  }

  .form-check .jq-checkbox.checked .jq-checkbox__div {
    width: 14px;
    height: 8px;
    margin: 0px 0 5px 0px;
    border-bottom: 2px solid $white;
    border-left: 2px solid $white;
    transform: rotate(-50deg);
  }

  .form-check .jq-checkbox.focused,
  .form-check .jq-radio.focused {
    border: none;
  }

  .form-check .jq-checkbox,
  .form-check .jq-radio {
    vertical-align: 0px;
    width: 26px;
    height: 25px;
    margin: 0 0px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: $blue_second;
    box-shadow: none;
    cursor: pointer;
    margin-left: 30px;
  }

  label {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: .28px;
    position: relative;
    cursor: pointer;

    &:before {
      display: none;
    }

    &:after {
      display: none;
    }

    a {
      cursor: pointer;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  @include _992 {
    .calculator_inner {
      display: flex;
      flex-direction: column;

      .div_td.left {
        width: 100%;
        padding: 0;
        vertical-align: top;
      }
    }

    .user_form {
      padding: 0 115px;
      padding-top: 40px;
    }

    .tribler .div_tr {
      margin-bottom: 0px;

    }

    .title_h3 {

      margin-bottom: 60px;
    }
  }

  @include _768 {
    .user_form {
      padding: 0 55px;
      padding-top: 40px;
    }
  }

  @include _576 {
    .div_table .div_tr {
      display: flex;
    }

    .calculator_inner {
      .div_td.left .left_blik {
        padding: 30px 15px;
      }
    }

    .user_form {
      padding: 0 0px;
      padding-top: 40px;
    }
  }

  @include _460 {
    .div_table .div_tr {
      flex-direction: column;
    }

    .div_table .strong {
      flex-direction: row;
    }

    .div_table .strong .div_td {
      width: 49% !important;
    }
  }
}